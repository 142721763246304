(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldKeywords", function () {
		return {
			restrict: "A",
			controller: function ($scope, lumaAPI) {

				const init = () => {
					// NOT SURE WHY LUMA CAN'T DO THIS - we can post process the options response if we need to
					lumaAPI.listOptionsForTypeField($scope.field.id).then(response => {
						let keywordResponse;
						if (response.data.every(keyword => !keyword.selected)) {
							keywordResponse = response.data;
						} else {
							keywordResponse = response.data.filter(keyword => keyword.selected);
						}
						$scope.keywordOptions = keywordResponse.map(keyword => {
							return {id:keyword.id, keywordID:keyword.id.toString(), name:keyword.value, selected: $scope.field.values?.filter(value=> +value.keywordID === keyword.id).length ? true : false};
						});
					});
				};
				init();
				$scope.$watch("field.values", (newValue, oldValue) => {
					// dropdown only works with field.values, not model.value
					if ($scope.model !== undefined && !angular.equals(newValue, oldValue)) {
						$scope.model.value = newValue;
					}
				});
			},
			scope: {
				field: "=",
				model: "=",
			},
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-keywords.template.html"
		};
	});
})(canopyCore);
